import * as Sentry from '@sentry/browser'

Sentry.init({
  dsn: 'https://441c0d54067168fc2c3293106efa1809@o4507424398901248.ingest.de.sentry.io/4507424957792336',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 0.1,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['_localhost', /^https:\/\/(app|app-aws|stg-v2).projektove.cz/],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

class ErrorsTracker extends HTMLElement {
  connectedCallback() {
    // we need to wait for the user to be set
    // (that should not be possible, but it happens from time to time)
    // setTimeout(this.initRollbar, 100)
    setTimeout(this.initSentry(), 100)
  }

  // initRollbar = () => {
  //   if (window.Rollbar && window._rollbarConfig && this.user) {
  //     window._rollbarConfig.payload.person = {
  //       id: `${this.tenant}:${this.user.id}`,
  //       username: `${this.user.firstname} ${this.user.lastname} [${this.tenant}]`,
  //       email: `${this.user.mail}`,
  //     }

  //     Rollbar.configure({ payload: window._rollbarConfig.payload })
  //   }
  // }

  initSentry = () => {
    Sentry.setUser({
      id: `${this.tenant}:${this.user.id}`,
      tenant: this.tenant,
      username: `${this.user.firstname} ${this.user.lastname}`,
      email: `${this.user.mail}`,
    })
    Sentry.setTag('tenant', this.tenant)
    Sentry.setTag('user_mode', this.user.admin ? 'admin' : 'regular')
  }
}

customElements.define('errors-tracker', ErrorsTracker)
